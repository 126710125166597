import { TableColumn, TableRowAction } from './table.model';
import React from 'react';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import { useErrorHandler } from '../../../../hooks/UseErrorHandler';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { QueryKey, useQueryClient } from 'react-query';
import { resetQueryFn } from '../../../../utils';

interface IProps<T extends GridValidRowModel> {
  params: GridRenderCellParams<T>;
  rowActions: TableRowAction<T>[];
  refetch: () => Promise<any>;
  queryKey: QueryKey;
}

function TableRowActionsCell<T extends GridValidRowModel>({ params, rowActions, refetch, queryKey }: IProps<T>) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const errorHandler = useErrorHandler();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const doDropdownAction = async (action: TableRowAction<T>, item: T) => {
    setAnchorEl(null);
    try {
      await action.action(item);
      if (!action.isNavigate) {
        enqueueSnackbar(
          t('common:confirm.success', [typeof action.text === 'function' ? action.text(params.row) : action.text]),
          { variant: 'success' },
        );
      }
      if (!action.keepDataAfterAction) {
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, ...new Array<QueryKey>().concat(queryKey)) });
        await refetch();
      }
    } catch (e) {
      errorHandler(String(action.value), e);
    }
  };

  const handleDropdownAction = (action: TableRowAction<T>, item: T) => {
    if (action.confirm !== undefined) {
      const confirmOptions = typeof action.confirm === 'function' ? action.confirm(item) : action.confirm;
      return confirm({
        title: confirmOptions.title ?? (typeof action.text === 'function' ? action.text(params.row) : action.text),
        description: confirmOptions.description ? t(String(confirmOptions.description)) : confirmOptions.description,
      })
        .then(() => doDropdownAction(action, item))
        .catch(() => null);
    } else {
      return doDropdownAction(action, item);
    }
  };

  return rowActions.some((x) => !x.hide || !x.hide(params.row)) ? (
    <>
      <IconButton
        id="cell-button"
        aria-controls={open ? 'cell-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="cell-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'cell-button',
          dense: true,
        }}
      >
        {rowActions
          .filter((x) => !x.hide || !x.hide(params.row))
          .map((action, idx) => (
            <MenuItem key={idx} onClick={() => handleDropdownAction(action, params.row)}>
              {typeof action.text === 'function' ? action.text(params.row) : action.text}
            </MenuItem>
          ))}
      </Menu>
    </>
  ) : null;
}

export function useTableRowActionsColumn<T extends GridValidRowModel>(
  rowActions: TableRowAction<T>[],
  refetch: () => Promise<any>,
  queryKey: QueryKey,
) {
  const { t } = useTranslation();
  return new TableColumn<T>({
    field: '__action__',
    headerName: t('common:table.rowActions'),
    hideable: false,
    width: 50,
    disableExport: true,
    disableColumnMenu: true,
    cellClassName: 'p-0',
    renderCell: (params) => <TableRowActionsCell params={params} rowActions={rowActions} refetch={refetch} queryKey={queryKey} />,
    renderHeader: () => <></>,
  });
}
