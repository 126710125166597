import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import { Route } from 'react-router-dom';
import React from 'react';
import { PassportList } from '../PassportList';
import { PASSPORT_ROUTES } from '../../route';
import { PassportEdit } from '../PassportEdit';

export const PASSPORT_ROUTE = (
  <Route>
    <Route path={PASSPORT_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<PassportList />} />
    </Route>
    <Route path={PASSPORT_ROUTES.create()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<PassportEdit />} />
    </Route>
    <Route path={PASSPORT_ROUTES.edit()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<PassportEdit />} />
    </Route>
  </Route>
);
