import { PropsWithChildren } from 'react';

interface IProps<T extends Iterable<any>> {
  conditions: T[];
  value: T;
  negate?: boolean
}

export function ConditionalBlock<T extends Iterable<any>>({ conditions, value, negate, children }: PropsWithChildren<IProps<T>>) {
  return (!negate && conditions.includes(value) || negate && !conditions.includes(value)) ? <>{children}</> : null;
}