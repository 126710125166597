import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useOptions } from '../../../hooks/UseOptions';
import { GenderEnum, OrganizationTypeEnum, PassportBrief, PassportTypeEnum } from '../../../model';
import { EntityQueryKey } from '../../../query/query.keys';
import { ErrorRoute } from '../../error/routes';
import { useDocumentTitle } from '../../../hooks/UseDocumentTitle';
import { PassportService } from '../../../api/passport/passport.service';
import { PassportEditDto } from '../../../model/dto/passport/passport.edit.dto';
import { ClientService } from '../../../api/client/client.service';
import { PASSPORT_ROUTES } from '../../route';
import AutocompleteControl from '../../../components/common/ui/AutocompleteControl';
import { Length, Match, NotEmpty } from '../../../hooks/UseValidation/validators';
import { EditPage } from '../../../components/common/layout/EditPage';
import { ConditionalBlock, FileControl } from '../../../components/common';
import { EditPassportValidator } from '../../../service/validator/passport';
import InputControl from '../../../components/common/ui/InputControl';
import SelectControl from '../../../components/common/ui/SelectControl';
import DateControl from '../../../components/common/ui/DateControl';
import { MaskedInput } from '../../../components/common/ui/MaskedInput';
import { useTranslation } from 'react-i18next';

export function PassportEdit() {
  const uuid = useParams<{ uuid: string }>().uuid;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const types = useOptions(PassportTypeEnum, 'passport:type.');
  const genders = useOptions(GenderEnum, 'passport:gender.');
  const [userError, setUserError] = useState<string>();
  const [disableActions, setDisableActions] = useState(false);

  const { data: entity } = useQuery(
    [EntityQueryKey.Passport, uuid],
    () => PassportService.get<PassportBrief>(uuid ?? 'false'),
    {
      enabled: !!uuid,
      onSuccess: (data) => {
        if (data.isVerified) {
          navigate(ErrorRoute.ACCESS_DENIED, { replace: true });
        }
        setDto(new PassportEditDto(data));
      },
    },
  );
  useDocumentTitle({ params: [PassportService.fullView(entity)] });
  const [dto, setDto] = useState(new PassportEditDto(entity));
  const updateDto = (key: keyof PassportEditDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));

  const { data: pClient } = useQuery([EntityQueryKey.Client, OrganizationTypeEnum.PHYSICAL, dto.userUuid], () =>
    dto.userUuid
      ? ClientService.list<any>({
          filter: { type: OrganizationTypeEnum.PHYSICAL, user: dto.userUuid, isActive: true },
        })
      : null,
  );

  useEffect(() => {
    setDisableActions(!pClient?.items?.length);
  }, [pClient?.items?.length])

  return (
    <EditPage
      titleKey="passport:edit.pageTitle"
      titleParams={[PassportService.getNumberView(entity)]}
      validator={EditPassportValidator}
      dto={dto}
      queryKey={EntityQueryKey.Passport}
      routes={PASSPORT_ROUTES}
      service={PassportService}
      disableActions={disableActions}
    >
      <AutocompleteControl
        required
        value={dto.userUuid}
        entity={EntityQueryKey.User}
        labelKey="passport:field.user"
        onChange={(val, option) => {
          if (option?.attrs?.isDeleted === true) {
            setUserError(t('passport:error.userDeleted') ?? undefined);
            setDisableActions(true);
          }
          updateDto('userUuid', val)
        }}
        validators={[NotEmpty]}
        disabled={!!uuid}
        error={userError}
      />
      <InputControl labelKey="passport:field.client" value={pClient?.items[0]?.shortName ?? ''} disabled onChange={() => null}
                    error={dto.userUuid && !pClient?.items?.length ? t('passport:error.clientNotFound') : null}/>
      <SelectControl options={types} labelKey="passport:field.type" value={dto.type} onChange={(val) => updateDto('type', val)} disabled/>
      <ConditionalBlock value={dto.type} conditions={[PassportTypeEnum.PASSPORT]}>
        <InputControl required labelKey="passport:field.series" value={dto.series} onChange={(val) => updateDto('series', val)}
                      type="masked" inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '0000' }} validators={[NotEmpty, Length(4, 4)]}/>
        <InputControl required labelKey="passport:field.number" value={dto.number} onChange={(val) => updateDto('number', val)}
                      type="masked" inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000' }} validators={[NotEmpty, Length(6, 6)]}/>
      </ConditionalBlock>
      <InputControl required labelKey="passport:field.lastName" value={dto.lastName} onChange={(val) => updateDto('lastName', val)}
                    validators={[NotEmpty, Length(60)]}/>
      <InputControl required labelKey="passport:field.firstName" value={dto.firstName} onChange={(val) => updateDto('firstName', val)}
                    validators={[NotEmpty, Length(60)]}/>
      <InputControl labelKey="passport:field.secondName" value={dto.secondName} onChange={(val) => updateDto('secondName', val)}
                    validators={[Length(60)]}/>
      <DateControl required labelKey="passport:field.birthDate" value={dto.birthDate} onChange={(val) => updateDto('birthDate', val)}
                   validators={[NotEmpty]}/>
      <SelectControl options={genders} labelKey="passport:field.gender" value={dto.gender} onChange={(val) => updateDto('gender', val)}/>
      <ConditionalBlock value={dto.type} conditions={[PassportTypeEnum.PASSPORT]}>
        <InputControl required labelKey="passport:field.birthAddress" value={dto.birthAddress} onChange={(val) => updateDto('birthAddress', val)}
                      validators={[NotEmpty, Length(150)]}/>
      </ConditionalBlock>
      <InputControl required labelKey="passport:field.issuedBy" value={dto.issuedBy} onChange={(val) => updateDto('issuedBy', val)}
                    validators={[NotEmpty, Length(200)]}/>
      <ConditionalBlock value={dto.type} conditions={[PassportTypeEnum.PASSPORT]}>
        <InputControl required labelKey="passport:field.departmentCode" value={dto.departmentCode} onChange={(val) => updateDto('departmentCode', val)}
                      type="masked" inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000-000', unmask: false }} validators={[NotEmpty, Match(/^\d{3}-\d{3}$/)]}/>
      </ConditionalBlock>
      <DateControl required labelKey="passport:field.issueDate" value={dto.issueDate} onChange={(val) => updateDto('issueDate', val)}
                   validators={[NotEmpty]}/>
      <FileControl accept="image/jpeg,image/png,application/pdf" limit={5} labelKey="passport:field.files" value={dto.files} onChange={(val) => updateDto('files', val)}/>
    </EditPage>
  );
}
