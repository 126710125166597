import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableDateColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../../api/user/user.service';
import { PassportBrief } from '../../../model';
import { useBoolTableColumn } from '../../../components/common/data/DataTable/table.utils';
import { PassportService } from '../../../api/passport/passport.service';
import { ClientService } from '../../../api/client/client.service';
import { DownloadLink } from '../../../components/common';
import { Stack } from '@mui/material';
import { getFileView } from '../../../utils';
import { FileService } from '../../../api/file/file.service';

export function useColumns() {
  const { t } = useTranslation();
  const isDeleted = useBoolTableColumn<PassportBrief>({
    headerName: t('common:field.deletedM'),
    field: 'isDeleted',
  });
  const isActive = useBoolTableColumn<PassportBrief>({
    headerName: t('common:field.isActiveM'),
    field: 'isActive',
    sortable: true,
  });
  const isVerified = useBoolTableColumn<PassportBrief>({
    headerName: t('passport:field.isVerified'),
    field: 'isVerified',
    sortable: true,
  });

  return useMemo(
    () => [
      new TableLinkColumn<PassportBrief>({
        headerName: t('passport:field.fullName'),
        field: 'lastName,firstName,secondName',
        text: (row) => PassportService.getFullName(row),
        linkProps: (row) => ({ to: PassportService.detailsUrl(row.uuid) }),
        sortable: true,
      }),
      new TableColumn<PassportBrief>({
        headerName: t('passport:field.numberView'),
        field: 'series,number',
        sortable: false,
        valueGetter: ({ row }) => PassportService.getNumberView(row),
      }),
      new TableDateColumn<PassportBrief>({
        headerName: t('passport:field.birthDate'),
        field: 'birthDate',
        sortable: false,
      }),
      new TableColumn<PassportBrief>({
        headerName: t('passport:field.gender'),
        field: 'gender',
        sortable: false,
        valueFormatter: ({ value }) => t(`passport:gender.${value}`),
      }),
      new TableLinkColumn<PassportBrief>({
        headerName: t('passport:field.user'),
        field: 'userUuid',
        linkProps: (row) => ({ to: UserService.detailsUrl(row.userUuid) }),
        sortable: true,
      }),
      new TableLinkColumn<PassportBrief>({
        headerName: t('passport:field.client'),
        field: 'clientGuid',
        linkProps: (row) => ({ to: ClientService.detailsUrl(row.clientGuid) }),
        sortable: true,
      }),
      isVerified,
      new TableColumn<PassportBrief>({
        headerName: t('passport:field.files'),
        field: 'files',
        sortable: false,
        renderCell: ({ row }) =>
          row.files?.length > 0 ? (
            <Stack direction="column" sx={{ overflow: 'hidden' }}>
              {row.files.map((file, idx) => {
                const view = t(...getFileView(file)) ?? file.name;
                return (
                  <DownloadLink key={idx} size="small" title={view as any}  sx={{ maxWidth: '100%' }} downloader={() => FileService.download(file.uuid)}>
                    <small style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {t(...getFileView(file)) as any}
                    </small>
                  </DownloadLink>
                )
              })}
            </Stack>
          ) : null,
        minWidth: 150,
        flex: 1,
      }),
      new TableDateTimeColumn<PassportBrief>({
        headerName: t('common:field.createDate'),
        field: 'createDate',
        sortable: true,
      }),
      new TableDateTimeColumn<PassportBrief>({
        headerName: t('common:field.updateDate'),
        field: 'updateDate',
        sortable: true,
      }),
      isActive,
      isDeleted,
    ],
    [],
  );
}
