import { EditableEntityDto } from '../common';
import { ClientBrief } from '../../interfaces';
import { OrganizationTypeEnum } from '../../enum';

export class ClientEditDto extends EditableEntityDto<ClientBrief> {
  type = OrganizationTypeEnum.PHYSICAL;
  user = '';
  isActive = true;
  inn = '';
  snils = ''
  guidRegion = '';
  index = '';
  district: string | null = '';
  locality = '';
  street: string | null = '';
  house: string | null = '';
  building: string | null = '';
  room: string | null = '';
  additionalInfo: string | null = '';
  ogrn = '';
  kpp = '';
  ogrnip = '';
  fullName = '';
  shortName = '';
  email = '';
  phone = '';
  liquidationDate = '';

  constructor(entity: ClientBrief|undefined) {
    super(entity);
    Object.entries(entity ?? {}).forEach(([key, val]) => {
      if (this.hasOwnProperty(key)) {
        this[key as keyof ClientEditDto] = val as never ?? '';
      }
    });
    if (entity?.owner) {
      this.user = entity.owner.uuid;
    }
  }
}