import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import { Route } from 'react-router-dom';
import React from 'react';
import { UserList } from '../UserList';
import { USER_ROUTES } from '../../route';
import { ChangePassword } from '../ChangePassword';
import { UserEdit } from '../UserEdit';

export const USER_ROUTE = (
  <Route>
    <Route path={USER_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<UserList />} />
    </Route>
    <Route path={USER_ROUTES.create()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<UserEdit />} />
    </Route>
    <Route path={USER_ROUTES.edit()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<UserEdit />} />
    </Route>
    <Route path={USER_ROUTES.changePassword} element={<ChangePassword />} />
  </Route>
);
