import { TableColumn, TableGroupAction, TableRowAction } from './table.model';
import { ListRequestDto } from '../../../../model/interfaces/common/list.request.dto';
import { QueryKey } from 'react-query';
import { FilterItem } from '../../../../model/filter';
import { SelectItem } from '../../../../model/interfaces/common/select.item';
import { ListResponseDto } from '../../../../model/interfaces/common/list.response.dto';
import { BaseEntity } from '../../../../model';

export interface DataTableProps<TModel extends BaseEntity, TFilter extends Record<string, any> = TModel> {
  id: string;
  queryKey: QueryKey;
  itemId?: string | ((item: any) => string);
  columns: TableColumn<TModel>[];
  fetchData: (requestData: ListRequestDto<TFilter>) => Promise<ListResponseDto<TModel>>;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  onPage?: number;
  filter?: FilterItem[];
  rowActions?: TableRowAction<TModel>[];
  groupActions?: TableGroupAction[];
  toolbarActions?: SelectItem[]; // пока достаточно, потом мб расширим
}

export const DEFAULT_DATA_TABLE_PROPS: Partial<DataTableProps<any>> = {
  sortOrder: 'asc',
  onPage: 10,
  filter: [],
  rowActions: [],
  groupActions: [],
  toolbarActions: [],
};
