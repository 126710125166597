import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError, BaseEntity, ErrorResponse } from '../../../../model';
import { Title } from '../../typography';
import { PropsWithChildren, useCallback, useState } from 'react';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { Content } from '../Content';
import Grid2 from '@mui/material/Unstable_Grid2';
import ModelValidator from '../../../../service/validator/model.validator';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { resetQueryFn } from '../../../../utils';
import { EntityQueryKey } from '../../../../query/query.keys';
import { EntityRoutes } from '../../../../model/route/entity.routes';
import { CustomErrorMessage, useErrorHandler } from '../../../../hooks/UseErrorHandler';
import { useSnackbar } from 'notistack';
import { Button, Stack } from '@mui/material';
import { EditableEntityDto } from '../../../../model/dto/common';
import { AxiosResponse } from 'axios';
import { useConfirm } from 'material-ui-confirm';
import { CrudService } from '../../../../api/crud.service';

interface IProps<TEntity extends BaseEntity, TDto extends EditableEntityDto<TEntity>> extends PropsWithChildren {
  titleKey: string;
  titleParams?: any[];
  dto: TDto;
  validator: ModelValidator<TDto>;
  queryKey: EntityQueryKey;
  routes: EntityRoutes;
  service: CrudService;
  validationErrors?:  (errors: string[]) => void,
  apiErrors?: (errors: Record<string, string>) => void,
  disableActions?: boolean;
  customMessage?: CustomErrorMessage;
}

interface IMutationParams {
  stay: boolean;
}

export function EditPage<TEntity extends BaseEntity, TDto extends EditableEntityDto<TEntity>>(props: IProps<TEntity, TDto>) {
  const uuid = useParams<{ uuid: string }>()?.uuid;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);
  const [validateOn, setValidateOn] = useState(false);

  const apiErrorHandler = useCallback((errors: ApiError[]) => {
    if (props.apiErrors && Array.isArray(errors)) {
      props.apiErrors(errors.reduce((acc, err) => { acc[err.code] = t(`error:apiError.${err.code}`); return acc; }, {} as Record<string, string>))
    }
  }, [props.apiErrors]);

  const save = useCallback((dto: TDto, stay: boolean) => {
    setValidateOn(true);
    window.scrollTo(0, 0);
    const errors = props.validator.validate(dto);
    if (props.validationErrors) {
      props.validationErrors(errors);
    }
    if (errors.length === 0) {
      mutation.mutate({ stay });
      setLoading(true);
    }
  }, []);

  const remove = useCallback(() =>
    confirm({
      title: t('common:confirm.delete.title'),
      description: t('common:confirm.delete.description'),
    }).then(() => {
      setLoading(true);
      removeMutation.mutate()
    }), [props.dto?.uuid]
  );

  const mutation = useMutation<{ guid?: string, uuid: string; }, AxiosResponse<ErrorResponse>, IMutationParams>(() => props.service.save(props.dto), {
    onSuccess: async (result, params: IMutationParams) => {
      enqueueSnackbar({ message: t(`${props.queryKey}:message.success.edit`), variant: 'success' });
      await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, props.queryKey) });
      if (params.stay) {
        navigate(props.routes.edit(result.uuid), { replace: true });
      } else {
        // navigate(props.routes.details(result.guid ?? result.uuid), { replace: true }); пока у нас нет деталок
        navigate(props.routes.list(), { replace: true });
      }
    },
    onError: (error) => {
      console.log(props.customMessage)
      const errors = errorHandler(`Failed to save ${props.queryKey}`, error, props.customMessage);
      apiErrorHandler(errors)
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const removeMutation = useMutation(() => props.service.remove(props.dto.uuid ?? 'false'), {
    onSuccess: async () => {
      await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, props.queryKey) });
      navigate(props.routes.list());
    },
    onError: (error) => {
      const errors = errorHandler(`Failed to remove ${props.queryKey}`, error);
      if (apiErrorHandler) {
        apiErrorHandler(errors)
      }
    },
    onSettled: () => {
      setLoading(false);
    },
  })

  return (<>
    <Title text={t(`${props.titleKey}.${uuid ? 'edit' : 'create'}`, { ...props.titleParams })} />
    <ValidationContext.Provider value={{ validateOn }}>
      <Content>
        <Grid2 container rowSpacing={2}>
          <Grid2 xs={12}  lg={9} xl={6}>
            {props.children}
          </Grid2>
          <Grid2 xs={12}>
            <Stack direction="row" spacing={2}>
              <LoadingButton loading={loading} disabled={props.disableActions} variant="outlined" color="success" onClick={() => save(props.dto, false)}>{t('common:button.save')}</LoadingButton>
              <LoadingButton loading={loading} disabled={props.disableActions} variant="outlined" onClick={() => save(props.dto, true)}>{t('common:button.apply')}</LoadingButton>
              {props.dto?.uuid && <LoadingButton loading={loading} disabled={props.disableActions} variant="outlined" color="error" onClick={() => remove()}>{t('common:button.delete')}</LoadingButton>}
              <Button onClick={() => navigate(-1)}>{t('common:button.cancel')}</Button>
            </Stack>
          </Grid2>
        </Grid2>
      </Content>
    </ValidationContext.Provider>
  </>)
}