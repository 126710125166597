import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { EmployeeBrief, SelectItem } from '../../../model';
import { useTranslation } from 'react-i18next';
import { useGroupActions, useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { EMPLOYEE_ROUTES } from '../../route';
import { EmployeeService } from '../../../api/employee/employee.service';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../../auth/auth.service';

export function useActions(): [TableRowAction<EmployeeBrief>[], TableGroupAction[], SelectItem[]] {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = AuthService.getUser();

  const rowActions = useRowActions<EmployeeBrief>({
    remove: {
      action: (row) => EmployeeService.remove(row.uuid),
      hide: (row) => row.isDeleted,
    },
    toggleActive: {
      action: (row) => EmployeeService.toggle('isActive', row.uuid, !row.isActive),
      hide: (row) => row.isDeleted,
      confirm: (row) => ({
        description: `employee:confirm.toggleActive.${!row.isActive}`,
      }),
    },
    other: [
      {
        value: 'toggleAdmin',
        text: (row) => t(`employee:button.toggleAdmin.${!row.isAdmin}`),
        action: (row) => EmployeeService.toggle('isAdmin', row.uuid, !row.isAdmin),
        hide: (row) => row.isDeleted,
        confirm: (row) => ({
          description: `employee:confirm.toggleAdmin.${!row.isAdmin}`,
        }),
      },
      {
        value: 'toggleConfirmed',
        text: (row) => t(`employee:button.toggleConfirmed.${!row.isConfirmed}`),
        action: (row) => EmployeeService.toggle('isConfirmed', row.uuid, !row.isConfirmed),
        hide: (row) => row.isDeleted,
        confirm: (row) => ({
          description: `employee:confirm.toggleConfirmed.${!row.isConfirmed}`,
        }),
      },
      {
        value: 'toggleDefault',
        text: (row) => t(`employee:button.toggleDefault.${!row.isDefault}`),
        action: (row) => EmployeeService.toggle('isDefault', row.uuid, !row.isDefault),
        hide: (row) => row.isDeleted,
        confirm: (row) => ({
          description: `employee:confirm.toggleDefault.${!row.isDefault}`,
        }),
      },
      {
        value: 'edit',
        text: () => t('common:button.edit'),
        action: (row) => navigate(EMPLOYEE_ROUTES.edit(row.uuid)),
        hide: () => !user?.isAdmin,
        isNavigate: true,
      },
    ]
  });

  const groupActions = useGroupActions({
    remove: (selected) => EmployeeService.remove(selected as string[]),
    setActive: (selected) => EmployeeService.toggle('isActive', selected as string[], true),
    removeActive: (selected) => EmployeeService.toggle('isActive', selected as string[], false),
    other: [
      {
        value: 'setAdmin',
        text: t(`employee:button.toggleAdmin.true`),
        action: (sm) => EmployeeService.toggle('isAdmin', sm as string[], true),
        confirm: {
          description: t('employee:confirm.toggleAdmin.true'),
        },
      },
      {
        value: 'removeAdmin',
        text: t(`employee:button.toggleAdmin.false`),
        action: (sm) => EmployeeService.toggle('isAdmin', sm as string[], false),
        confirm: {
          description: t('employee:confirm.toggleAdmin.false'),
        },
      },
      {
        value: 'setConfirm',
        text: t(`employee:button.toggleConfirmed.true`),
        action: (sm) => EmployeeService.toggle('isConfirmed', sm as string[], true),
        confirm: {
          description: t('employee:confirm.toggleConfirmed.true'),
        },
      },
      {
        value: 'removeConfirm',
        text: t(`employee:button.toggleConfirmed.false`),
        action: (sm) => EmployeeService.toggle('isConfirmed', sm as string[], false),
        confirm: {
          description: t('employee:confirm.toggleConfirmed.false'),
        },
      },
      {
        value: 'setDefault',
        text: t(`employee:button.toggleDefault.true`),
        action: (sm) => EmployeeService.toggle('isDefault', sm as string[], true),
        confirm: {
          description: t('employee:confirm.toggleDefault.true'),
        },
      },
      {
        value: 'removeDefault',
        text: t(`employee:button.toggleDefault.false`),
        action: (sm) => EmployeeService.toggle('isDefault', sm as string[], false),
        confirm: {
          description: t('employee:confirm.toggleDefault.false'),
        },
      },
    ],
  });

  const toolbarActions = [
    {
      value: EMPLOYEE_ROUTES.create(),
      text: t('common:button.add'),
    },
  ];

  return [rowActions, groupActions, toolbarActions];
}