import { CheckCircleOutlined, SvgIconComponent } from '@mui/icons-material';
import InputControl, { IProps as InputProps } from '../InputControl';
import { InputAdornment } from '@mui/material';

interface IProps extends InputProps {
  badge?: SvgIconComponent;
  hideBadge?: boolean;
  color?: 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  active?: boolean;
}

const DEFAULTS: Partial<IProps> = {
  badge: CheckCircleOutlined,
  hideBadge: false,
  color: 'success',
  active: true,
};

export function BadgedInputControl(props: IProps) {
  props = { ...DEFAULTS, ...props };
  const Badge = props.badge as any;

  return (
    <InputControl
      {...props}
      inputProps={{
        ...props.inputProps,
        endAdornment: props.hideBadge ? null : (
          <InputAdornment position="end">
            <Badge color={props.active ? props.color : 'disabled'} />
          </InputAdornment>
        ),
      }}
    />
  );
}
