import { useTranslation } from 'react-i18next';
import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { SelectItem } from '../../../model';
import { SIGNER_ROUTES } from '../../route';
import { SignQueueListItem } from '../../../model/interfaces/signqueue';

export function useActions(): [TableRowAction<SignQueueListItem>[], TableGroupAction[], SelectItem[]] {
  const { t } = useTranslation();

  const toolbarActions = [
    {
      value: SIGNER_ROUTES.create(),
      text: t('common:button.add'),
    },
  ];

  return [[], [], toolbarActions];
}
