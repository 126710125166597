import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { SelectItem, CertificateItemBrief } from '../../../model';
import { useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { CertificateService } from '../../../api/certificate/certificate.service';
import { useTranslation } from 'react-i18next';

export function useActions(): [TableRowAction<CertificateItemBrief>[], TableGroupAction[], SelectItem[]] {
  const { t } = useTranslation();

  const rowActions = useRowActions<CertificateItemBrief>({
    other: [
      {
      value: 'revoke',
      text: t('certificate:button.revoke') ?? '',
      action: (row) => CertificateService.revoke(row.uuid),
      hide: (row) => row.isRevoked,
      confirm: {
        description: 'certificate:confirm.revoke',
      },
    },
      {
      value: 'renew',
      text: t('certificate:button.renew') ?? '',
      action: (row) => CertificateService.renew(row.uuid),
      hide: (row) => !row.isRevoked && row.isValid,
      confirm: {
        description: 'certificate:confirm.renew',
      },
    },
  ]
  });

  return [rowActions, [], []];
}
