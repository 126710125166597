import { useState } from 'react';
import { useParams } from 'react-router-dom';
import InputControl from '../../../components/common/ui/InputControl';
import { useQuery } from 'react-query';
import { EntityQueryKey } from '../../../query/query.keys';
import { EmployeeBrief, OrganizationTypeEnum } from '../../../model';
import { EditPage } from '../../../components/common/layout/EditPage';
import CheckboxControl from '../../../components/common/ui/CheckboxControl';
import { EMPLOYEE_ROUTES } from '../../route';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import { EmployeeService } from '../../../api/employee/employee.service';
import { EmployeeEditDto } from '../../../model/dto/employee';
import AutocompleteControl from '../../../components/common/ui/AutocompleteControl';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { EditEmployeeValidator } from '../../../service/validator/employee';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../../hooks/UseDocumentTitle';
import { UserService } from '../../../api/user/user.service';

export function EmployeeEdit() {
  const uuid = useParams<{ uuid: string }>().uuid;
  const { t } = useTranslation();

  const { data: entity } = useQuery([EntityQueryKey.Employee, uuid], () => EmployeeService.get<EmployeeBrief>(uuid ?? 'false'), {
    enabled: !!uuid,
    onSuccess: (data) => {
      setDto(new EmployeeEditDto(data));
    },
  });
  useDocumentTitle({ params: [UserService.getFullName(entity?.user)] });
  const [dto, setDto] = useState(new EmployeeEditDto(entity));
  const updateDto = (key: keyof EmployeeEditDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));
  const listClients = (user: string, term: string | (string | number)[], skip?: number) =>
    AutocompleteService.fetch(EntityQueryKey.Client, term, skip, {
      type: [OrganizationTypeEnum.INDIVIDUAL, OrganizationTypeEnum.JURIDICAL],
      notUser: user,
    });

  return (
    <EditPage titleKey="employee:edit.pageTitle" titleParams={[UserService.getFullName(entity?.user)]} dto={dto} queryKey={EntityQueryKey.Employee} routes={EMPLOYEE_ROUTES} validator={EditEmployeeValidator} service={EmployeeService}>
      <AutocompleteControl required value={dto.user} disabled={!!dto.uuid} entity={EntityQueryKey.User} labelKey="employee:field.user" onChange={(val) => {
        updateDto('user', val);
        if (!dto.uuid) {
          updateDto('client', null);
        }
      }} validators={[NotEmpty]}/>
      <AutocompleteControl required value={dto.client} disabled={!!dto.uuid || !dto.user} error={entity?.client?.isDeleted ? t('employee:error.clientDeleted') : null} labelKey="employee:field.client" onChange={(val) => updateDto('client', val)} validators={[NotEmpty]}
                           options={(key, term, skip) => listClients(dto.user, term, skip)} entity={EntityQueryKey.Client} auxQueryKey={dto.user}/>
      <InputControl labelKey="employee:field.post" value={dto.post} onChange={(val) => updateDto('post', val)} validators={[Length(255)]}/>
      <CheckboxControl labelKey="common:field.isActiveM" value={dto.isActive} onChange={(val) => updateDto('isActive', val)}/>
      <CheckboxControl labelKey="employee:field.isAdmin" value={dto.isAdmin} onChange={(val) => updateDto('isAdmin', val)}/>
      <CheckboxControl labelKey="employee:field.isDefault" value={dto.isDefault} onChange={(val) => updateDto('isDefault', val)}/>
      <CheckboxControl labelKey="employee:field.isConfirmed" value={dto.isConfirmed} onChange={(val) => updateDto('isConfirmed', val)}/>
    </EditPage>
  )
}