import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from './locales/common_ru.json';
import error from '../page/error/locales/ru.json';
import admin from '../page/admin/locales/ru.json';
import user from '../page/user/locales/ru.json';
import client from '../page/client/locales/ru.json';
import employee from '../page/employee/locales/ru.json';
import leftMenu from '../components/common/layout/LeftMenu/locales/ru.json';
import restore from '../page/restore/locales/ru.json';
import document from '../page/document/locales/ru.json';
import signer from '../page/signer/locales/ru.json';
import passport from '../page/passport/locales/ru.json';
import certificate from '../page/certificate/locales/ru.json';
import account from '../page/account/locales/ru.json';
import snils from '../page/snils/locales/ru.json';

export const resources = {
  ru: {
    common,
    error,
    admin,
    leftMenu,
    user,
    client,
    employee,
    restore,
    document,
    signer,
    passport,
    certificate,
    account,
    snils,
  },
};

i18next.use(initReactI18next).init({
  resources,
  supportedLngs: ['ru'],
  fallbackLng: 'ru',
  debug: false,
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
    format: (value, format, lng, options) => {
      if (format === 'filesize') {
        return new Intl.NumberFormat(lng, {
          style: 'unit',
          unit: options?.unit,
        })
          .format(value)
          .toUpperCase();
      }

      return value;
    },
  },
});

export default i18next;
