import { useTranslation } from 'react-i18next';
import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { DocumentBrief, DocumentStatusEnum, SelectItem } from '../../../model';
import { DOCUMENT_ROUTES } from '../../route';
import { useGroupActions, useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { DocumentService } from '../../../api/document/document.service';

export function useActions(): [TableRowAction<DocumentBrief>[], TableGroupAction[], SelectItem[]] {
  const { t } = useTranslation();

  const rowActions = useRowActions<DocumentBrief>({
    remove: {
      action: (row) => DocumentService.remove(row.uuid),
      hide: (row) => row.status !== DocumentStatusEnum.DRAFT || !row.activeVersion,
    },
  });

  const groupActions = useGroupActions({
    remove: (selected) => DocumentService.remove(selected as string[]),
  });

  const toolbarActions = [
    {
      value: DOCUMENT_ROUTES.create(),
      text: t('common:button.add'),
    },
  ];

  return [rowActions, groupActions, toolbarActions];
}
