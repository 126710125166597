import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import { Route } from 'react-router-dom';
import React from 'react';
import { EMPLOYEE_ROUTES } from '../../route';
import { EmployeeList } from '../EmplyeeList';
import { EmployeeEdit } from '../EmployeeEdit';

export const EMPLOYEE_ROUTE = (
  <Route>
    <Route path={EMPLOYEE_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<EmployeeList/>} />
    </Route>
    <Route path={EMPLOYEE_ROUTES.create()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<EmployeeEdit/>} />
    </Route>
    <Route path={EMPLOYEE_ROUTES.edit()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<EmployeeEdit/>} />
    </Route>
  </Route>
);
