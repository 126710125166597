import { useTranslation } from 'react-i18next';
import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { UserBrief, SelectItem } from '../../../model';
import { UserService } from '../../../api/user/user.service';
import { AuthService } from '../../../auth/auth.service';
import { USER_ROUTES } from '../../route';
import { useGroupActions, useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { useNavigate } from 'react-router-dom';
import { GridRowSelectionModel } from '@mui/x-data-grid';

export function useActions(): [TableRowAction<UserBrief>[], TableGroupAction[], SelectItem[]] {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = AuthService.getUser();

  const rowActions = useRowActions<UserBrief>({
    remove: {
      action: (row) => UserService.remove(row.uuid),
      hide: (row) => row.isDeleted || row.isAdmin || AuthService.getUser()?.uuid === row.uuid,
    },
    toggleActive: {
      action: (row) => UserService.toggle('isActive', row.uuid, !row.isActive),
      hide: (row) => row.isDeleted || row.isAdmin || AuthService.getUser()?.uuid === row.uuid,
      confirm: (row) => ({
        description: `user:confirm.toggleActive.${!row.isActive}`,
      }),
    },
    other: [
      {
        value: 'edit',
        text: () => t('common:button.edit'),
        action: (row) => navigate(USER_ROUTES.edit(row.uuid)),
        hide: (row) => row.isAdmin && row.uuid !== user?.uuid,
        isNavigate: true,
      },
      {
        value: 'toggleAdmin',
        text: (row) => t(`user:button.toggleAdmin.${!row.isAdmin}`),
        action: (row) => UserService.toggle('isAdmin', row.uuid, !row.isAdmin),
        hide: (row) => !AuthService.getUser()?.isSuperAdmin || row.isDeleted || row.isAdmin || AuthService.getUser()?.uuid === row.uuid,
        confirm: (row) => ({
          description: `user:confirm.toggleAdmin.${!row.isAdmin}`,
        }),
      },
      {
        value: 'toggleEmail',
        text: (row) => t(`user:button.toggleEmail.${!row.emailVerified}`),
        action: (row) => UserService.toggle('emailVerified', row.uuid, !row.emailVerified),
        hide: (row) => row.isDeleted || row.isAdmin || AuthService.getUser()?.uuid === row.uuid,
        confirm: (row) => ({
          description: `user:confirm.toggleEmail.${!row.emailVerified}`,
        }),
      },
      {
        value: 'togglePhone',
        text: (row) => t(`user:button.togglePhone.${!row.phoneVerified}`),
        action: (row) => UserService.toggle('phoneVerified', row.uuid, !row.phoneVerified),
        hide: (row) => !row.phone || row.isDeleted || row.isAdmin || AuthService.getUser()?.uuid === row.uuid,
        confirm: (row) => ({
          description: `user:confirm.togglePhone.${!row.phoneVerified}`,
        }),
      },
    ],
  });

  const groupActions = useGroupActions({
    remove: (selected) => UserService.remove(selected as string[]),
    setActive: (selected) => UserService.toggle('isActive', selected as string[], true),
    removeActive: (selected) => UserService.toggle('isActive', selected as string[], false),
    other: [
      ...(AuthService.getUser()?.isSuperAdmin ? [{
        value: 'setAdmin',
        text: t(`user:button.toggleAdmin.true`),
        action: (sm: GridRowSelectionModel) => UserService.toggle('isAdmin', sm as string[], true),
        confirm: {
          description: t('user:confirm.toggleAdmin.true'),
        },
      },
      {
        value: 'removeAdmin',
        text: t(`user:button.toggleAdmin.false`),
        action: (sm: GridRowSelectionModel) => UserService.toggle('isAdmin', sm as string[], false),
        confirm: {
          description: t('user:confirm.toggleAdmin.false'),
        },
      }] : []),
      {
        value: 'setEmail',
        text: t(`user:button.toggleEmail.true`),
        action: (sm) => UserService.toggle('emailVerified', sm as string[], true),
        confirm: {
          description: 'user:confirm.toggleEmail.true',
        },
      },
      {
        value: 'removeEmail',
        text: t(`user:button.toggleEmail.false`),
        action: (sm) => UserService.toggle('emailVerified', sm as string[], false),
        confirm: {
          description: 'user:confirm.toggleEmail.false',
        },
      },
      {
        value: 'setPhone',
        text: t(`user:button.togglePhone.true`),
        action: (sm) => UserService.toggle('phoneVerified', sm as string[], true),
        confirm: {
          description: 'user:confirm.togglePhone.true',
        },
      },
      {
        value: 'removePhone',
        text: t(`user:button.togglePhone.false`),
        action: (sm) => UserService.toggle('phoneVerified', sm as string[], false),
        confirm: {
          description: 'user:confirm.togglePhone.false',
        },
      },
    ],
  });

  const toolbarActions = [
    {
      value: USER_ROUTES.create(),
      text: t('common:button.add'),
    },
  ];

  return [rowActions, groupActions, toolbarActions];
}
