import React, { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import { FileContent } from '../../../../model/interfaces/file';
import { useErrorHandler } from '../../../../hooks/UseErrorHandler';

interface IProps extends LoadingButtonProps {
  downloader: () => Promise<FileContent | null>;
}

export function DownloadLink({ downloader, children, ...rest }: IProps) {
  const [loading, setLoading] = useState(false);
  const errorHandler = useErrorHandler();
  const download = useCallback(async () => {
    setLoading(true);
    try {
      const file = await downloader();
      if (file) {
        const linkSource = `data:${file.mediaType};base64,${file.content}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = file.name;
        downloadLink.click();
      }
    } catch (e) {
      errorHandler('download', e);
    } finally {
      setLoading(false);
    }
  }, [downloader]);

  return (
    <LoadingButton  variant="text" onClick={download} loading={loading} loadingPosition="end" endIcon={<></>} sx={{ width: 'fit-content' }} {...rest}>
      {children}
    </LoadingButton>
  )
}