import { ClientEditDto } from '../../../model/dto/client';
import { OrganizationTypeEnum } from '../../../model';

export const PrettyClientEditDto = (dto: ClientEditDto): ClientEditDto => {
  if (!dto.type) return dto;

  const result: Partial<ClientEditDto> = {
    uuid: dto.uuid,
    type: dto.type,
    user: dto.user,
    isActive: dto.isActive,
    inn: dto.inn,
    guidRegion: dto.guidRegion,
    index: dto.index,
    locality: dto.locality,
    district: dto.district === '' ? null : dto.district,
    street: dto.street === '' ? null : dto.street,
    house: dto.house === '' ? null : dto.house,
    building: dto.building === '' ? null : dto.building,
    room: dto.room === '' ? null : dto.room,
    additionalInfo: dto.additionalInfo === '' ? null : dto.additionalInfo,
  }
  switch (dto.type) {
    case OrganizationTypeEnum.PHYSICAL:
      result.snils = dto.snils;
      break;
    case OrganizationTypeEnum.JURIDICAL:
      result.ogrn = dto.ogrn;
      result.kpp = dto.kpp;
      result.fullName = dto.fullName;
      result.shortName = dto.shortName;
      // fall through
    case OrganizationTypeEnum.INDIVIDUAL:
      result.ogrnip = dto.ogrnip;
      result.email = dto.email;
      result.phone = dto.phone;
      result.liquidationDate = dto.liquidationDate;
      break;
  }
  return result as ClientEditDto;
}